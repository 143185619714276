@import "scss/image-gallery";
html, body {
  height: 100%;
}
body {
  background-color: #faf8f8!important;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5em;
  color: #22292e;
  margin: 0;
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5em; }

blockquote footer:before, blockquote small:before {
  content: '\2014 \00A0'; }

h1 {
  font-size: 3em;
  line-height: 1.15em; }

h2 {
  font-size: 2.6em; }

h3 {
  font-size: 1.7em;
  line-height: 1.4em;
  margin: 20px 0 10px; }

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
  margin-top: 10px;
  margin-bottom: 10px; }

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px; }

h6 {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500; }

h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px; }

p {
  margin: 0 0 10px;
  font-size: 16px;
}

blockquote p {
  font-style: italic; }

a{
  color: inherit;
  text-decoration: none;
}

legend {
  border-bottom: 0;
  margin-bottom: 20px;
  font-size: 21px;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent; }

*:focus {
  outline: 0; }

a:focus, a:active,
button:active, button:focus, button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important; }

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857; }

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #AAAAAA;
  font-weight: 400; }

footer {
  padding: 15px 0; }

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none; }

footer ul li {
  display: inline-block; }

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block; }

footer ul li a:hover {
  text-decoration: none; }

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee; }

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

svg {
  -ms-touch-action: none;
  touch-action: none; }

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-weight: 400;
  line-height: 1;
  color: #777; }

h4 small, h5 small, h6 small {
  font-size: 75%; }

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px; }

code, kbd, pre, samp {
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace; }

img {
  vertical-align: middle;
  border: 0; }

#EpicPlanApp{
  position: relative;
  height: 100%;
}

/// React DateTime Picker
.rdt {
  position: relative; }
.rdtPickerOpenUp .rdtPicker{
  top: auto;
  bottom: 100%;
  &:before{
    top: auto;
    bottom: -5px;
    border-left: .4em solid transparent;
    border-right: .4em solid transparent;
    border-bottom: none;
    border-top: .4em solid #fff;
  }
  &:after{
    border-bottom: none;
  }
}
.rdt .rdtPicker {
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  margin-top: -20px;
  visibility: hidden;
  display: block;
  opacity: 0; }
.rdt.rdtOpen .rdtPicker {
  opacity: 1;
  visibility: visible;
  margin-top: 5px; }
.rdt input.form-control {
  border: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f44336), to(#f44336)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#f44336, #f44336), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#f44336, #f44336), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: background 0s ease-out;
  -o-transition: background 0s ease-out;
  transition: background 0s ease-out;
  float: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  font-weight: 300;
  width: 100%;
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.428571429;
  display: block;
  width: 100%;
  color: #555; }
.rdt input.form-control:focus {
  outline: none;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f44336), to(#f44336)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
  background-image: -webkit-linear-gradient(#f44336, #f44336), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: -o-linear-gradient(#f44336, #f44336), -o-linear-gradient(#D2D2D2, #D2D2D2);
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 100% 2px, 100% 1px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  -webkit-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  min-width: 160px; }
.rdtPicker:before {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  top: -5px;
  left: 10px;
  right: auto;
  color: #FFFFFF;
  border-bottom: .4em solid;
  border-right: .4em solid transparent;
  border-left: .4em solid transparent; }
.rdtPicker:after {
  border-bottom: .4em solid #FFFFFF;
  border-right: .4em solid transparent;
  border-left: .4em solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  top: -5px;
  left: 10px; }

.rdtPicker {
  display: block;
  top: 40px; }

.rdtStatic .rdtPicker {
  -webkit-box-shadow: none;
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #FFFFFF !important;
  border-collapse: collapse; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px; }

.rdtPicker td {
  cursor: pointer; }

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%; }
.rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
  background-color: #f44336 !important;
  color: #FFFFFF;
  -webkit-box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4); }

.rdtDays tr .dow {
  border-bottom: 1px solid #E3E3E3;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: inherit;
  padding-bottom: 5px;
  padding-top: 10px; }

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
  color: #bdbdbd; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px; }

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: #f44336; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%; }

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }
.rdtCounter .rdtCount {
  padding: 7px;
  height: 40px;
  border: 1px solid transparent; }

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: #f44336;
  border-radius: 50%;
  border: 1px solid #f44336; }

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px; }

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #eeeeee;
  -webkit-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979; }

.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px; }

.rdtMonths,
.rdtYears {
  padding-bottom: 10px; }
.rdtMonths .rdtMonth,
.rdtMonths .rdtYear,
.rdtYears .rdtMonth,
.rdtYears .rdtYear {
  display: inline-block;
  width: 56px;
  height: 56px;
  line-height: 56px;
  margin: 3px 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center; }
.rdtMonths .rdtMonth.rdtActive,
.rdtMonths .rdtYear.rdtActive,
.rdtYears .rdtMonth.rdtActive,
.rdtYears .rdtYear.rdtActive {
  background-color: #f44336 !important;
  color: #FFFFFF; }

// contentEditable placeholder
[contenteditable=true]:empty:not(:focus):before{
  content:attr(placeholder);
  color: #8c8c8c;
  font-style:italic;
}

.horizontal-divider{
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #bbb;
  line-height: 0.1em;
  margin: 20px 0;

  .divider-text{
    background:#fff;
    padding:0 10px;
  }
}

/*
  Chart styles
*/
.ct-bar {
  stroke-width: 30px;
}
.ct-chart-white-colors .ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px; }

.ct-chart-white-colors .ct-series-a .ct-point, .ct-chart-white-colors .ct-series-a .ct-line, .ct-chart-white-colors .ct-series-a .ct-bar, .ct-chart-white-colors .ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8); }

.ct-chart-white-colors .ct-label.ct-horizontal.ct-end {
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-white-colors .ct-label {
  color: rgba(255, 255, 255, 0.7); }

.ct-chart .ct-label.ct-horizontal {
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  text-anchor: middle;
}
.ct-chart-line .ct-label, .ct-chart-bar .ct-label {
  display: block;
  display: -ms-flexbox;
  display: flex; }

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1; }

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #00bcd4; }

.ct-series-a .ct-slice-pie, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-area {
  fill: #00bcd4; }

.ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: #f44336; }

.ct-series-b .ct-slice-pie, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-area {
  fill: #f44336; }

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
  stroke: #ff9800; }

.ct-series-c .ct-slice-pie, .ct-series-c .ct-slice-donut-solid, .ct-series-c .ct-area {
  fill: #ff9800; }

/* Color Pick overrides */
.circle-picker {
  width: 222px!important;
  padding: 5px 0 0 5px!important;
  margin-bottom: -8px!important;
  overflow: hidden!important;
}
